import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Basildon",
  "priceBand": 1,
  "title": "Basildon",
  "description": "todo\n"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Planning a children’s party in Basildon, Essex`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      